.convergence {
  position: relative;
}

.group135__img {
  width: 100%;
}

.convergence__container {
  background-color: #000000;
  height: 350px;
  width: 100%;
  position: relative;
  margin-top: -5px;
}

.convergence__title {
  position: absolute;
  bottom: 60px;
  left: 75px;
}

.convergence__txt {
  color: #fff;
  text-align: left;
  font-size: 45px;
}

@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .convergence__title {
    position: absolute;
    bottom: 32px;
    left: 20px;
  }
  .convergence__container {
    background-color: #000000;
    height: 160px;
    width: 100%;
    position: relative;
  }
  .convergence__txt {
    color: #fff;
    text-align: left;
    font-size: 16px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .convergence__title {
    left: 30px;
    bottom: 45px;
  }
  .convergence__container {
    height: 220px;
  }
  .convergence__txt {
    font-size: 25px;
  }
}

.circle__location {
  position: absolute;
  left: 59%;
  top: 9px;
}

.circle__flex {
  display: flex;
}

.circle {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.yellow {
  background-color: #EFCD3C;
  opacity: 1;
}

.white {
  background-color: #fff;
  position: absolute;
  left: 23px;
  opacity: 0.8;
}

.hall-move {
  width: 180px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
  border-radius: 40px;
  padding: 20px 25px;
  cursor: pointer;
}

.hall-move--txt {
  font-size: 28px;
  color: #fff;
}

.hall__move--container {
  position: absolute;
  left: 75px;
  top: 45px;
}

.FaArrowRight {
  font-size: 20px;
  color: #000;
}

.hall-move--inner {
  display: flex;
  align-items: center;
}

.right--arrow {
  width: 39px;
  height: 26px;
  background-color: #fff;
  border-radius: 40px;
  padding: 20px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .hall-move {
    width: 75px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #fff;
    border-radius: 40px;
    padding: 6px 20px;
    cursor: pointer;
  }
  .circle {
    width: 14px;
    height: 14px;
  }
  .circle__location {
    left: 59%;
    top: 3px;
  }
  .white {
    left: 8px;  
  }
  .hall__move--container {
    left: 20px;
    top: 25px;
  }
  .hall-move--txt {
    font-size: 14px;
  }
  .right--arrow {
    width: 20px;
    height: 26px;
    background-color: #fff;
    border-radius: 40px;
    padding: 6px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .FaArrowRight {
    font-size: 12px;
    color: #000;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .hall-move--txt {
    font-size: 16px;
  }
  .circle__location {
    top: 6px;
  }
  .circle {
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
  .hall__move--container {
    left: 30px;
    top: 40px;
  }
}

@keyframes arrowMove {
  0% { transform: translateX(0); }
  100% { transform: translateX(10px); }
}

@keyframes arrowMoveReverse {
  0% { transform: translateX(10px); }
  100% { transform: translateX(0); }
}

.FaArrowRight {
  transition: transform 0.3s ease;
}

.right--arrow:hover .FaArrowRight {
  animation: arrowMove 0.3s forwards;
}

.right--arrow .FaArrowRight {
  animation: arrowMoveReverse 0.3s forwards;
}


