.header {
  width: 100%;
  height: 100%;
  background: #000000 0% 0% no-repeat padding-box;
}
.header-content {
  max-width: 2560px;
  margin: 0 auto;
}
.header-inner {
  padding: 54px 0 0 63px;
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .header-inner  {
    padding: 19px 0 0 18px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .header-logo img {
    width: 84px;
    height: 26px;
  }
}

.title-wrap {
  margin-top: 20px;
  height: 23vw;
}
.title-wrapper {
  height: 100%;
  float: right;
  padding-right: 100px;
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .title-wrapper {
    margin-top: 100px;
    margin-bottom: 80px;
  }
}
.titleimg {
  width: 100%;
  height: 100%;
}
.titleimg img {
  width: 50vw;
}


.subtitle-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
}
.subtitle-text-section {
  margin-top: 9.3vw;
  padding-bottom: 4.1vw;
  padding-left: 63px;
}
.subtitle-text {
  display: flex;
  gap: 120px;
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .subtitle-text{
    display: block;
  }
}

.subtitle-text h2 {
  color: var(--unnamed-color-ffffff);
  text-align: left;
  letter-spacing: -1.34px;
  color: #FFFFFF;
  font-family: 'Nanum Myeongjo', serif;
  font-size: 55px;
}

@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .subtitle-text h2 {
    font-size: 20px;
    letter-spacing: -0.79px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .subtitle-text h2 {
    font-size: 36px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .pt {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
