@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Nanum+Myeongjo:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+KR:wght@200..900&display=swap");

@font-face {
  font-family: "NanumSquareB";
  src: url("../public/font/NanumSquareB.otf");
}

@font-face {
  font-family: "NanumSquareEB";
  src: url("../public/font/NanumSquareEB.otf");
}

@font-face {
  font-family: "NanumSquareR";
  src: url("../public/font/NanumSquareR.otf");
}

@font-face {
  font-family: "musinsa-Medium";
  src: url("../public/font/musinsa-Medium.ttf");
}

.custom-font {
  font-family: 'Noto Serif KR', serif;
}

body {
  margin: 0;
  font-family: "Nanum Gothic", sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-10px);
  }
  100% {
    transform: translatey(0px);
  }
}

.floating {
  animation: float 3s ease-in-out infinite;
}
@keyframes slideLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes slideRight {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
} 
@layer utilities {
  .transform-style-3d {
    transform-style: preserve-3d;
  }
  .rotate-y-180 {
    transform: rotateY(180deg);
  }
  .backface-hidden {
    backface-visibility: hidden;
  }
}

* {
  box-sizing: inherit;
  -ms-overflow-style: none;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  display: none;
}

a {
  color: inherit;
  text-decoration: none;
}

ol,
ul {
  list-style: none;
}