.eview-container {
  background: #F2F1EC 0% 0% no-repeat padding-box;
  width: 100%;
  height: 100%;
  padding-bottom: 40px;
}
@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .eview-container {
    padding-bottom: 20px;
  }
}
.title-content {
  max-width: 2560px;
}

@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .e-title h1{
    font-size: 38px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .e-titlebox{
    padding: 20px 0 0 30px;
  }
}

.e-text {
  display: flex;
  padding: 100px 0 0 72px;
  position: relative;
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .e-text {
    padding: 20px 0 0 19px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .e-text {
    padding: 20px 0 0 30px;
  }
}
.gab {
  display: flex;
}
.layout-l {
  width: 100%;
}
.width {
  width: 50vw;
}
.stxt {
  font-size: 21px;
  font-family: "NanumSquareB";
  text-align: left;
  letter-spacing: -0.46px;
  color: #686868;
  line-height: 1.7;
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .m-etxt{
    font-size: 13px;
    color: #686868;
    line-height: 1.6;
    font-family: "NanumSquareB";
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .stxt {
    font-size: 13px;
  }
}

.layout-r {
  position: absolute;
  right: 0;
  bottom: -43px;
}
@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .layout-r {
    bottom: -24px;
  }
}

.layout-r div {
  width: 100%;
  height: 100%;
}
.layout-r div img {
  width: 43vw;
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .layout-r div img {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .layout-r div img {
    width: 40vw;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .bottom-img {
    position: absolute;
    left: 0;
    padding-top: 30px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .bottom-img div {
    width: 100%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .bottom-img div img {
    width: 100vw;
  }
}