.ins-container {
  width: 100%;
  height: 100%;
  background: #F2F1EC 0% 0% no-repeat padding-box;
}
.margin {
  max-width: 2560px;
}
.ins-header {
  height: 63px;
  display: flex;
  padding-top: 21px;
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .ins-header {
    padding-top: 7px;
    height: 25px;
  }
}
.ins-inner {
  width: 100%;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .ins-inner{
    padding: 0 20px;
    height: 25px;
  }
}
.ins-inner img {
  width: 132px;
  height: 42px;
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .ins-inner img{
    width: 38px;
    height: 12px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .ins-inner img  {
    width: 94px;
    height: 30px;
  }
}

.ins-head-r h3 {
  font-size: 28px;
  font-family: "NanumSquareB";
  text-align: left;
  letter-spacing: -0.63px;
  color: #3A3A3A;
  opacity: 1;
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .ins-head-r h3{
    font-size: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .ins-head-r h3 {
    font-size: 20px;
  }
}
.main-info {
  width: 100%;
  padding-top: 40px;
}
.main-info-img {
  text-align: center;

}
.info-box {
  width: 100%;
  height: 100%;
  text-align: center;
  margin-top: 30px;
}
.info-box img{
  width: 83vw;
}
.bottom {
  margin-bottom: 61px;
}
.top {
  margin-top: 61px;
}
.main-bottom-text p {
  font-size: 28px;
  font-family: "NanumSquareR";
  letter-spacing: -0.63px;
  color: #3A3A3A;
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .main-bottom-text p {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .main-bottom-text p  {
    font-size: 20px;
  }
}
.main-bottom {
  width: 100%;
  height: 100%;
  padding: 80px 0 30px 0;
}
.main-bottom-text {
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 40px;
}