.kview-container {
  background: #F2F1EC 0% 0% no-repeat padding-box;
  width: 100%;
  height: 100%;
}
.title-content {
  max-width: 2560px;
}
.titlebox {
  padding: 100px 0 0 72px;
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .titlebox   {
    padding: 24px 0 0 19px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .titlebox   {
    padding: 40px 0 0 30px;
  }
}
.title {
  height: 100%;
}
.title  h1 {
  text-align: left;
  letter-spacing: -1.32px;
  font-size: 60px;
  font-family: "NanumSquareEB";
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .title  h1  {
    font-size: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .title  h1  {
    font-size: 26px;
  }
}
.cy {
  color: #EFCD3C;
  margin-top: 10px;
}

.subtitlebox {
  padding: 48px 0 55px 72px;
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .subtitlebox {
    padding: 20px 0 15px 19px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .subtitlebox  {
    padding: 25px 0 30px 30px;
  }
}
.subtitle {
  box-sizing: border-box;
  text-align: left;
  letter-spacing: -0.44px;
  color: #686868;
  opacity: 1;
}
.subtitle p {
  font-size: 20px;
  font-family: "NanumSquareB";
  line-height: 1.8;
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .subtitle p  {
    font-size: 10px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .m-text {
    line-height: 1.6;
    font-size: 14px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .subtitle p  {
    font-size: 12px;
  }
}


.kview-content {
  height: 100%;
}
.kview-img-box {
  width: 100%;
  height: 100%;
}
.kview-img-box img {
  width: 100vw;
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .kview-img-box img  {
    display: none;
  }
}

