.meta-container {
  background: #F2F1EC 0% 0% no-repeat padding-box;
  width: 100%;
  height: 100%;
}

.max {
  max-width: 2560px;
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .max {
    position: relative;
    padding-top: 96px;

  }
}
.meta-header {
  background: #121212 0% 0% no-repeat padding-box;
  height: 55px;
  display: flex;
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .meta-header {
    position: absolute;
    height: 25px;
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .meta-header {
    background: #121212 0% 0% no-repeat padding-box;
    height: 30px;
    display: flex;
  }
}
.meta-inner {
  width: 100%;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.meta-inner img {
  width: 82px;
  height: 26px;
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .meta-inner img {
    width: 42px;
    height: 14px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .meta-inner img {
    width: 46px;
    height: 15px;
  }
}
.meta-head-r {
  text-align: left;
  letter-spacing: -0.37px;
  color: #FFFFFF;
  font-size: 17px;
  font-family: "NanumSquareB";
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .meta-head-r {
    font-size: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .meta-head-r {
    font-size: 12px;
  }
}

.meta-title-content {
  display: flex;
  justify-content: center;
  margin-top: 125px;

}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .meta-title-content {
    margin-top: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .meta-title-content {
    margin-top: 60px;
  }
}

.meta-title-box {
  text-align: center;
}
.meta-title h1 {
  letter-spacing: -1.65px;
  color: #232323;
  font-size: 70px;
  font-family: "NanumSquareB";
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .meta-title h1 {
    font-size: 22px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .meta-title h1 {
    font-size: 33px;
  }
}
.boxboar {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .boxboar {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .boxboar {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
.box{
  background: #232323 0% 0% no-repeat padding-box;
  border-radius: 10px;
  width: 59px;
  height: 9px;
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .box{
    width: 37px;
    height: 3px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .box{
    width: 28px;
    height: 4px;
  }
}
.meta-title p {
  text-align: center;
  letter-spacing: -0.4px;
  color: #686868;
  font-family: "NanumSquareB";
  font-size: 18px;
  line-height: 1.7;
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .m-meta-text {
    font-size: 10px;
    line-height: 1.5;
    letter-spacing: -0.4px;
    color: #686868;
    font-family: "NanumSquareB";
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .meta-title p {
    font-size: 12px;
  }
}

.meta-img-content {
  display:flex;
  justify-content: center;
  margin-top: 80px;
  padding-bottom: 40px;
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .meta-img-content {
    margin-top: 50px;
  }
}
.meta-img-box {
  width: 100%;
  height: 100%;
  display:flex;
  justify-content: center;
}
.meta-img-box img{
  width: 70vw;
} 