.footer {
  background: #1B1B1B 0% 0% no-repeat padding-box;
  width: 100%;
  height: 100%;
}
.footer-content {
  max-width: 2560px;
}
.footer-center {
  text-align: center;
  padding-top: 130px;
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .footer-center {
    padding-top: 27px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .footer-center {
    padding-top: 45px;
  }
}
.footer-center img {
  width: 361px;
  height: 114px;
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .footer-center img {
    width: 158px;
    height: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .footer-center img {
    width: 168px;
    height: 53px;
  }
}
.com {
  margin-top: 77px;
  margin-bottom: 77px;
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .com {
    margin-top: 29px;
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .com {
    margin-top: 31px;
    margin-bottom: 43px;
  }
}
.com-text {
  color: var(--unnamed-color-ffffff);
  letter-spacing: -1px;
  color: #FFFFFF;
  font-family: "NanumSquareR";
  font-size: 18px;
  line-height: 1.8;
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .m-footer {
    font-size: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .com-text {
    font-size: 12px;
  }
}

.f-icons .dis {
  width: 59px;
  height: 59px;
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .f-icons .dis {
    width: 30px;
    height: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .f-icons .dis {
    width: 27px;
    height: 27px;
  }
}
.youtube {
  margin-left: 40px;
  margin-right: 40px;
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .youtube {
    width: 35px;
    height: 35px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .youtube {
    width: 30px;
    height: 30px;
  }
}
.f-icons .Insta {
  width: 61px;
  height: 58px;
  margin-right: 40px;
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .f-icons .Insta {
    width: 30px;
    height: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .f-icons .Insta {
    width: 30px;
    height: 30px;
  }
}
.f-icons .in {
  width: 64px;
  height: 43px;
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .f-icons .in {
    width: 30px;
    height: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .f-icons .in {
    width: 30px;
    height: 26px;
  }
}
.footer-bottom {
  height: 78px;
  display: flex;
  background: #121212 0% 0% no-repeat padding-box;
  margin-top: 140px;
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .footer-bottom {
    height: 34px;
    margin-top: 25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .footer-bottom {
    height: 35px;
    margin-top: 37px;
  }
}
.footer-b-head {
  width: 100%;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .footer-b-head {
    padding: 0 20px;
  }
}
.footer-b-head h3 {
  text-align: left;
  letter-spacing: 0.76px;
  color: #FFFFFF;
  font-size: 19px;
  font-family: "NanumSquareR";
}
@media only screen and (min-width: 320px) and (max-width: 767px){ 
  .footer-b-head h3 {
    font-size: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){ 
  .footer-b-head h3 {
    font-size: 12px;
  }
}